import { Controller } from "stimulus"
import { getCookie, setCookie } from "../helpers/cookies"

export default class extends Controller {
  static targets = ['installButton']

  connect() {
    const installContainer = this.element
    const installButton = this.installButtonTarget

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
    }

    window.addEventListener('beforeinstallprompt', event => {
      if (getCookie('app-install-banner') !== 'dismissed' || !this.isPWA()) {
        window.deferredPrompt = event
        installContainer.classList.toggle('hidden', false)
      }
    })

    installButton.addEventListener('click', async () => {
      const promptEvent = window.deferredPrompt
      if (!promptEvent) return
      promptEvent.prompt()
      const result = await promptEvent.userChoice
      console.log(`result: ${result}`)
      window.deferredPrompt = null
      installContainer.classList.toggle('hidden', true)
    });

    window.addEventListener('appinstalled', _event => {
      window.deferredPrompt = null
      setCookie('app-install-banner', 'dismissed', 1000)
    })
  }

  closeBanner() {
    const installContainer = this.element
    installContainer.classList.toggle('hidden', true)
    const cookieCount = getCookie('app-install-banner-dismissed-count')
    const count = cookieCount ? parseInt(cookieCount) : 0
    // if they have closed it more than three times then expire
    // the cookie 3 days from now otherwise every hour
    const numberOfDays = count >= 3 ? 3 : 1/24/60
    setCookie('app-install-banner', 'dismissed', numberOfDays)
    setCookie('app-install-banner-dismissed-count', count + 1, 30)
  }

  isIOS() {
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
    const iOS_1to12 = /iPad|iPhone|iPod/.test(platform)

    const iOS13_iPad = (platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    const iOS1to12quirk = function () {
      var audio = new Audio() // temporary Audio object
      audio.volume = 0.5 // has no effect on iOS <= 12
      return audio.volume === 1
    };

    return !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk())
  }

  isPWA() {
    // another way to get the value:
    // document.querySelector('meta[name="is_pwa"]').content
    return this.element.dataset.isPwa === 'true'
  }
}
