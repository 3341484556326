import ChartController from "stimulus-chartjs"
import Chart from "chart.js/auto"

const getColor = colorName => {
  const div = document.createElement('div')
  div.style.color = `var(${colorName})`
  div.style.display = "hidden"
  document.body.appendChild(div)
  const color = window.getComputedStyle(div).getPropertyValue('color')
  document.body.removeChild(div)
  return color
}

export default class extends ChartController {
  connect() {
    Chart.defaults.color = getColor("--color-text-primary")
    Chart.defaults.borderColor = getColor("--border-color")
    super.connect()
  }
}
