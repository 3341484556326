const getCookie = name => {
  const elements = document.cookie.split(';')
  for (let i = 0; i < elements.length; i++) {
    const [key, value] = elements[i].split('=')
    if (key.trim() === name) return value
  }
}

const setCookie = (name, value, exdays) => {
  const date = new Date()
  // const exdays = 1/24/60 // hide for a hour
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = exdays !== undefined ? `;expires=${date.toGMTString()}` : ''
  document.cookie = `${name}=${value}${expires}`
}

export { getCookie, setCookie }
