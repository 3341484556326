import { Controller } from "stimulus"

export default class extends Controller {
  openModal() {
    const modal = document.getElementById('modal_root')
    modal.classList.toggle('modal-active', true)
    const backdrop = document.getElementById('modal_backdrop')
    backdrop.classList.toggle('hidden', false)
  }

  closeModal() {
    const modal = document.getElementById('modal_root')
    modal.classList.toggle('modal-active', false)
    const backdrop = document.getElementById('modal_backdrop')
    backdrop.classList.toggle('hidden', true)
  }
}
