import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['menuContents', 'menuButton']

  connect() {
    window.addEventListener('click', event => {
      if (event.target !== this.menuButtonTarget && !this.menuButtonTarget.contains(event.target)) {
        this.menuContentsTarget.classList.toggle('hidden', true)
      }
    })
  }

  toggleMenu() {
    this.menuContentsTarget.classList.toggle('hidden')
  }
}
