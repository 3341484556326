import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['unitInput', 'amountField']

  connect() {
    if (this.unitInputTarget.value === 'remainder') {
      this.amountFieldTarget.classList.toggle('hidden', true)
    }
    this.unitInputTarget.addEventListener('change', this.handleUnitChange.bind(this))
  }

  handleUnitChange(event) {
    if (event.target.value === 'remainder') {
      this.amountFieldTarget.classList.toggle('hidden', true)
    } else {
      this.amountFieldTarget.classList.toggle('hidden', false)
    }
  }
}
