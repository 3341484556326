import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      handle: ".draggable-handle",
      onEnd: function (event) {
        var itemEl = event.item
        fetch(itemEl.dataset.updatePositionUrl, {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          },
          body: JSON.stringify({ new_position: event.newIndex + 1 })
        })
      }
    })
  }
}
