import { Controller } from "stimulus"

export default class extends Controller {
  openModal() {
    const backdrop = document.getElementById('modal_backdrop')
    backdrop.classList.toggle('hidden', false)
    const modal = document.getElementById('modal_root')
    modal.classList.toggle('modal-active', true)
  }

  connect() {
    this.element.addEventListener('click', async event => {
      event.preventDefault()
      const data = await fetch(this.element.href, {
        method: 'GET',
        credentials: 'same-origin'
      }).then(response => {
        return response.text()
      })
      const modal = document.getElementById('modal_root')
      modal.innerHTML = data
      this.addFormSubmitListeners(modal)
      this.openModal()
    })
  }

  addFormSubmitListeners(modal) {
    modal.querySelectorAll('form').forEach(form => {
      form.addEventListener('submit', async (event) => {
        event.preventDefault()
        const formData = new FormData(form)
        await fetch(form.action, {
          method: form.method,
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
          credentials: 'same-origin',
          body: formData
        }).then(async (response) => {
          if (response.redirected) {
            document.body.innerHTML = await response.text()
          } else {
            const modal = document.getElementById('modal_root')
            modal.innerHTML = await response.text()
            this.addFormSubmitListeners(modal)
          }
        })
      })
    })
  }
}
